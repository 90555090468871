// extracted by mini-css-extract-plugin
export var backgroundImage = "project-details-module--backgroundImage--1N04g";
export var container = "project-details-module--container--Ggwca";
export var content = "project-details-module--content--hlEIM";
export var contentContainer = "project-details-module--contentContainer--D8jtP";
export var contentSubContainer = "project-details-module--contentSubContainer--BfvY9";
export var description = "project-details-module--description--LRTM7";
export var header = "project-details-module--header--5C4cm";
export var infoContainer = "project-details-module--infoContainer--7YGdF";
export var subContainer = "project-details-module--subContainer--efcJa";
export var title = "project-details-module--title--Zc7JC";
export var underConstruction = "project-details-module--underConstruction--AhdZ0";
export var underConstructionContainer = "project-details-module--underConstructionContainer--oxGXg";
export var wrapper = "project-details-module--wrapper--+l1T2";