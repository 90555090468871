import React from "react"
import { graphql } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage } from "gatsby-plugin-image"
import Menu from "../components/Menu"
import MobileMenu from "../components/MobileMenu"
import Slider from "react-slick"
import "@brainhubeu/react-carousel/lib/style.css"
import "../styles/global.scss"
import * as styles from "../styles/project-details.module.scss"
import { useTranslation } from "gatsby-plugin-react-i18next"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { SEO } from "../components/SEO"

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const ProjectDetails = ({ data }) => {
  const [width, setWidth] = React.useState(isBrowser ? window.innerWidth : 0)

  const { t } = useTranslation()

  const [images] = React.useState(
    data.featuredImgs.nodes.map(node => (
      <GatsbyImage
        image={node.childImageSharp.gatsbyImageData}
        alt={node.name}
      />
    ))
  )

  React.useEffect(() => {
    function handleResize() {
      if (isBrowser) setWidth(window.innerWidth)
    }
    if (isBrowser) window.addEventListener("resize", handleResize)
  })

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return isBrowser ? (
    <div className={styles.wrapper}>
      <BackgroundImage
        className={styles.backgroundImage}
        {...convertToBgImage(
          data.markdownRemark.frontmatter.backgroundImg.childImageSharp
            .gatsbyImageData
        )}
      >
        {width >= 1220 ? (
          <Menu type="project" />
        ) : (
          <MobileMenu type="project" />
        )}
        <div className={styles.container}>
          <div className={styles.subContainer}>
            <div className={styles.contentContainer}>
              <h1 className={styles.header}>
                {data.markdownRemark.frontmatter.title}
              </h1>
              <div className={styles.contentSubContainer}>
                <div>
                  <p className={styles.title}>
                    {t("projects.details.project")}
                  </p>
                  <p className={styles.content}>
                    {data.markdownRemark.frontmatter.project}
                  </p>
                  <p className={styles.title}>
                    {t("projects.details.location")}
                  </p>
                  <p className={styles.content}>
                    {data.markdownRemark.frontmatter.locationLong}
                  </p>
                  <p className={styles.title}>{t("projects.details.area")}</p>
                  <p className={styles.content}>
                    {data.markdownRemark.frontmatter.area}
                  </p>
                  <p className={styles.title}>
                    {t("projects.details.studyType")}
                  </p>
                  <p className={styles.content}>
                    {data.markdownRemark.frontmatter.studyType}
                  </p>
                  <p className={styles.title}>
                    {t("projects.details.schedule")}
                  </p>
                  <p className={styles.content}>
                    {data.markdownRemark.frontmatter.schedule}
                  </p>
                </div>
                <div className={styles.infoContainer}>
                  <Slider {...settings}>{images}</Slider>
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                      __html: data.markdownRemark.html,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </div>
  ) : (
    <></>
  )
}

export const Head = ({ data, pageContext }) => {
  return (
    <SEO
      title={"Onarland - " + data.markdownRemark.frontmatter.project}
      description={data.markdownRemark.frontmatter.description}
      language={pageContext.language === "en" ? "en-gb" : "gr"}
    />
  )
}

export const query = graphql`
  query ProjectDetails(
    $language: String!
    $type: String
    $pagePath: String
    $imagesPath: String
  ) {
    markdownRemark(
      frontmatter: {
        type: { eq: $type }
        slug: { eq: $pagePath }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        title
        description
        project
        status
        locationLong
        area
        studyType
        schedule
        date
        backgroundImg {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      html
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    featuredImgs: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { regex: $imagesPath }
      }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
      }
    }
  }
`

export default ProjectDetails
